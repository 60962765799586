<template>
	<div class="main-content">
		<ktv-breadcrumb :title="`${FormStatus}` + ' Form'" :page="$t('Add', 'Add')"
			:folder="$t('User Dashboard', 'User Dashboard')"
		/>
		<div>
			<ktv-back @click="backToList()" />
			<b-row class="mt-3">
				<b-col cols="8">
					<ktv-accor :title="$t('Dashboard Data', 'Dashboard Data')" :footer=" FormStatus !== 'View'"
						name="accor-users"
					>
						<template #content>
							<b-form-group class="col-md-12 mt-2" :label="$t('Dashboard Name', 'Dashboard Name')">
								<b-form-input v-model="form.DashName" v-validate="'required'" name="Dashboard Name"
									:state="validateState('Dashboard Name')" type="text"
								/>
								<b-form-invalid-feedback>
									{{ veeErrors.first("Dashboard Name") }}
								</b-form-invalid-feedback>
							</b-form-group>
							<b-form-group class="col-md-12 mt-2" :label="$t('Board ID', 'Board ID')">
								<b-form-input v-model="form.BoardID" v-validate="'required'" name="Board ID"
									:state="validateState('Board ID')" type="text"
								/>
								<b-form-invalid-feedback>
									{{ veeErrors.first("Board ID") }}
								</b-form-invalid-feedback>
							</b-form-group>
							<!-- <b-form-group id="fieldset-1" description="" :label="$t('Status', 'Board ID')"
								:disabled="action === 'update' ? false : true">
								<b-form-radio-group v-slot="{ ariaDescribedby }" label="Select Options : ">
									<b-form-radio v-model="detailItems.GenderID" :aria-describedby="ariaDescribedby"
										name="some-radios" value="5">Male</b-form-radio>
									<b-form-radio v-model="detailItems.GenderID" :aria-describedby="ariaDescribedby"
										name="some-radios" value="6">Female</b-form-radio>
								</b-form-radio-group>
							</b-form-group> -->
							<b-form-group class="col-md-12 mt-2" :label="$t('Description', 'Description')">
								<b-form-textarea v-model="form.Description" v-validate="'required'" name="Description"
									:state="validateState('Description')" type="text"
								/>
								<b-form-invalid-feedback>
									{{ veeErrors.first("Description") }}
								</b-form-invalid-feedback>
							</b-form-group>
						</template>
						<template #footerright>
							<!-- <b-button variant="outline-primary" size="sm" class="float-right mr-2"
								@click="save()"> Save </b-button> -->
							<ktv-button :text="$t('Save', 'Save')" icon="save" :loading="loading" color="green-dark"
								@click="save()"
							/>
						</template>
					</ktv-accor>
				</b-col>
				<b-col cols="4" />
			</b-row>
		</div>
	</div>
</template>
<script>
// import ListProduct from "./ListProducts"
// import ListTrans from "./ListTrans"
	// eslint-disable-next-line no-unused-vars
	import { mapActions, mapGetters } from "vuex"
	import { KtvButton, KtvAccor, KtvHeadForm } from "@/components"

	export default {
		metaInfo: {
			title: "User Dashboard Form",
		},
		components: {
			KtvButton,
			KtvAccor,
			// eslint-disable-next-line vue/no-unused-components
			KtvHeadForm,
		// ListProduct,
		// ListTrans
		},
		data() {
			return {
				result: [],
				loading: true,
				DashID: this.$route.query.DashID,
				FormStatus: this.$route.query.DashID === "0" ? "Add" : "Update",
				form: {
					DashID: "",
					DashName: "",
					BoardID: "",
					Description: "",
					ActiveStatus: "yes",
					MetabaseStatus: 1,
					StatusCode: "active",
					Remarks: "",
					CreatedBy: "",
					LastModifiedBy: ""
				},
				serverParams: {
					columnFilters: {
						// Name: "",
						// DashName: "",
						// "DashID": this.$route.query.DashID,
						"BoardID": "",
						"DashName": ""
					},
					sort: [
						{
							field: "",
							type: "",
						},
					],
					currentPage: 1,
					rowPerpage: 1000,
				},
			}
		},
		computed: {
		// ...mapGetters({
		// options: "USERDASHBOARD/getOptions",
		// opsiDisplay: "USERDASHBOARD/getOpsiDisplay",
		// error: "USERDASHBOARD/getError",
		// }),
		},
		watch: {
			error(val) {
				if (val != null) {
					this.makeToast("Error", "danger", val.message)
				}
			},
		},
		async mounted() {
			await this.FetchData()
			this.loading = false
		},
		methods: {
			...mapActions({
				// 	clearData: "USERDASHBOARD/clearData",
				getDataDetail: "USERDASHBOARD/getDataDetail",
				postData: "USERDASHBOARD/postData",
				putData: "USERDASHBOARD/putData",
			}),
			showLoadingMessage(message) {
				this.$swal.fire({
					title: `${message}`,
					animation: true,
					allowOutsideClick: false,
					onBeforeOpen: () => {
						this.$swal.showLoading()
					},
				})
			},
			save() {
				this.$validator.validateAll().then((result) => {
					if (!result) {
						return
					}
					// if ((this.form.PhoneNumber).charAt(0) !== "+") {
					// 	this.$swal("Warning!", "Phone number format is invalid.", "info");
					// 	return
					// }
					if (this.FormStatus === "Update") {
						// this.$swal({
						// 	title: "Are you sure?",
						// 	text: "Data will be updated.",
						// 	type: "question",
						// 	showCancelButton: true,
						// 	confirmButtonText: "Submit",
						// 	showLoaderOnConfirm: true,
						// 	preConfirm: () => {
						// 		return this.putData(this.form).then((datares) => {
						// 			return datares
						// 		})
						// 	},
						// 	allowOutsideClick: () => !this.$swal.isLoading(),
						// }).then((res) => {
						// 	if (res.value.success) {
						// 		this.$swal("Success!", "Data has been updated.", "success")
						// 	} else {
						// 		this.$swal("Error!", "Data failed to be updated.", "error")
						// 	}
						// })
						this.showLoadingMessage("Saving Data...")
						// console.log('this.form', this.form)
						this.$http
							.Put(this.form, this.$urlApi.userDashboard.update)
							.then((r) => {
								// console.log('r', r)
								if (r.success) {
									this.$swal.closeModal()
									this.$swal("Saved!", "Your data has been updated.", "success")
									this.$router.back()
								}
							})
							.catch((e) => {
								// console.log('e', e)
								this.$swal.closeModal()
								this.$swal("Failed!", e.response.data.error_message, "error")
							})
					} else if (this.FormStatus === "Add") {
						// this.$swal({
						// 	title: "Are you sure?",
						// 	text: "Data will be saved.",
						// 	type: "question",
						// 	showCancelButton: true,
						// 	confirmButtonText: "Submit",
						// 	showLoaderOnConfirm: true,
						// 	preConfirm: () => {
						// 		return this.postData(this.form).then((datares) => {
						// 			return datares
						// 		})
						// 	},
						// 	allowOutsideClick: () => !this.$swal.isLoading(),
						// }).then((res) => {
						// 	if (res.value.success) {
						// 		this.$swal("Success!", "Data has been saved.", "success").then(() => {
						// 			this.$router.back()
						// 		})
						// 	} else {
						// 		this.$swal("Error!", "Data failed to be saved.", "error")
						// 	}
						// })

						this.showLoadingMessage("Saving Data...")
						this.$http
							.Post(this.form, this.$urlApi.userDashboard.create)
							.then((r) => {
								// console.log('r', r)
								if (r.success) {
									this.$swal.closeModal()
									this.$swal("Saved!", "Your data has been added.", "success")
									this.$router.back()
								}
							})
							.catch((e) => {
								// console.log('e', e)
								this.$swal.closeModal()
								this.$swal("Failed!", e.response.data.error_message, "error")
							})
					}
				})

			},

			// getDetail(id) {
			// 	return new Promise((resolve, reject) => {
			// 		this.$http
			// 			.Get({ DashID: id }, this.$urlApi.userDashboard.detail.get)
			// 			.then((r) => {
			// 				if (r.success && r.results !== null) {
			// 					resolve(r.results)
			// 				} else {
			// 					this.$swal("Error!", r.error_massage, "error")
			// 				}
			// 			})
			// 			.catch((e) => {
			// 				this.$swal("Error!", e.response.data.message, "error")
			// 				reject(e)
			// 			})
			// 	})
			// },
			loadItems() {
				// console.log('wew')
				this.isLoading = true
				this.$http
					.Post(this.serverParams, this.$urlApi.userDashboard.list)
					.then((response) => {
						// console.log('resa', response)
						let obj = response.results.data.find(o => o.DashID === this.$route.query.DashID);
						// console.log('obj', obj)
						this.form = {
							DashID: obj.DashID,
							DashName: obj.DashName,
							BoardID: obj.BoardID,
							Description: obj.Description,
							ActiveStatus: obj.ActiveStatus,
							MetabaseStatus: obj.MetabaseStatus,
							StatusCode: obj.StatusCode,
							Remarks: obj.Remarks,
							CreatedBy: obj.CreatedBy,
							LastModifiedBy: obj.LastModifiedBy
						}
						this.loading = false

					})
					.catch((error) => {
						this.isLoading = false
						this.$swal("Error!", error.response.error_massage, "error")
					})
			},
			FetchData() {
				if (this.FormStatus === "Update") {
					// console.log('this.$route.query.DashID,', this.$route.query.DashID)
					this.loadItems().then((res) => {
						this.form = res.basic_data
					})
					this.loading = false
				} else {
					this.form = {
						DashID: "",
						DashName: "",
						BoardID: "",
						Description: "",
						ActiveStatus: "yes",
						MetabaseStatus: 1,
						StatusCode: "active",
						Remarks: "",
						CreatedBy: "",
						LastModifiedBy: ""
					}
					this.loading = false
				}
			},
			backToList() {
				// this.clearData()
				this.$router.back()
			},
		},
	}
</script>

<style scoped>
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Firefox */
input[type="number"] {
	-moz-appearance: textfield;
}

.image-label {
	font-style: italic;
	font-size: 95%;
	color: #ffa500;
}
</style>
